body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Sidebar {
  width: 120px;
  margin: 5px;
  position: sticky;
  top: 62px;
  align-self: flex-start;
  align-items: start;
}

.App{
    padding: 0;
}

.Header{
    display: flex;
    background-color: #F0F8FF;
    border-bottom: 1px solid #ddd;
    
}

.Sidebar .nav-item {
  width: 100%;
}

.Sidebar a {
  color: #444;
}

.Sidebar a:hover {
  background-color: #eee;
}

.Sidebar a:visited {
  color: #444;
}

.header-subtext {
    font-style: initial;
    font-weight: bold;
}

.author {
    font-size: 0.9em;
    font-style: oblique;
}

.ln-image {
    width: 40px;
    height: auto;
}

.reference-header {
    font-weight: bold;
}

.resume-content {
    margin: 5px;
}
